import { FC } from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Notification, RichText } from '@/shared/ui-kit';
import { ResultProps } from './result-modal.types';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { Button } from '@mantine/core';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { copyToClipboard } from '@/shared/lib/clipboard';
import styles from './result-modal.module.scss';

export const ResultModal: FC<ResultProps> = ({ opened, close, title, info, additional, orderDetails, order_id }) => {
  const { isMobile } = useMediaQuery();
  const [alertIsOpen, { open: onAlertOpen , close: onAlertClose }] = useDisclosure();

  const isBeautifulNumberSelected = orderDetails?.isUserSelectedNumber;

  const handleCopy = () => {
    if (orderDetails) {
      const textToCopy = `Номер заказа: ${order_id}\n` +
        `Ваш номер: ${formatPhoneNumber(orderDetails.selectedNumber, false)}\n` +
        `Адрес: ${orderDetails.deliveryAddress}`;
      copyToClipboard(textToCopy, onAlertOpen);
    }
  };

  let content;
  if (orderDetails && !orderDetails.isDelivery) {
    content = (
      <div className={styles.container}>
        <div className={styles.info}>{title && <h1 className={styles.title}>{title}</h1>}</div>
        <div className={styles.order}>
          <div className={styles.orderTitle}>
            <span>
              Номер заказа: <span className={styles.numberOrder}>{order_id}</span>
            </span>
            {isBeautifulNumberSelected && (
              <span>
                Ваш номер: <span className={styles.phoneNumber}>
                  {formatPhoneNumber(orderDetails.selectedNumber, false)}
                </span>
              </span>
            )}
          </div>
          {info && <RichText className={styles.info}>{info}</RichText>}
        </div>
        <div className={styles.titleInfo}>
          На контактный номер телефона {formatPhoneNumber(orderDetails.contactPhone, false)} придёт SMS с
          номером заказа. Продиктуйте его сотруднику салона для получения заказа.
        </div>
        <div className={styles.buttonsContainer}>
          <button onClick={close} className={styles.btn}>
            Понятно
          </button>
          <button onClick={handleCopy} className={styles.btnCopy}>
            Скопировать данные заказа
          </button>
        </div>
        {!isMobile && (
          <Notification onClose={onAlertClose} opened={alertIsOpen} label='Данные скопированы' />
        )}
      </div>
    );
  } else {
    content = (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {title && <h1 className={styles.title}>{title}</h1>}
          {info && <RichText className={styles.info}>{info}</RichText>}
        </div>
        {additional}
        <Button onClick={close} className={styles.button}>
          Понятно
        </Button>
      </div>
    );
  }

  return isMobile ? (
    <BottomSheet open={opened} onDismiss={close} className={styles.bottomsheet}>
      {content}
    </BottomSheet>
  ) : (
    <Modal opened={opened} onClose={close} contentClassName={styles.modal} className={styles.closeButton}>
      {content}
    </Modal>
  );
};
