import { unsecuredCopyToClipboard } from './unsecured-copy-to-clipboard';

export const copyToClipboard = async (text: string, onSuccess?: () => void, onError?: (error: unknown) => void) => {
  try {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      if (onSuccess) onSuccess();
    } else {
      unsecuredCopyToClipboard(text);
      if (onSuccess) onSuccess();
    }
  } catch (error) {
    console.error('Не удалось скопировать текст: ', error);
    if (onError) onError(error);
  }
};
