import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@mantine/core';
import cn from 'classnames';
import { MaskedDynamic } from 'imask';
import { PhoneInput } from '@/shared/ui-kit';
import { CurrencyInput } from '@/shared/ui-kit';
import { Mir, Visa, VisaBlack, Mastercard, Sbp } from '@/shared/assets/svg';
import { useAddPaymentTransactionMutation, useCheckOperatorQuery } from '@/store/api/payment/payment.api';
import { getMyTypeError } from '@/shared/lib/get-my-type-error';
import { PaymentError } from '@/store/api/payment/payment.type';
import { getOrigin } from '@/shared/lib/base-query';
import { storage } from '@/shared/lib/storage';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { ReplenishmentProps } from './replenishment.types';
import { REPLENISHMENT_CONSTANTS } from './constants';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import styles from './content.module.scss';

export const ReplenishmentContent: FC<ReplenishmentProps> = ({ lightMode, mobile, openModal }) => {
  const router = useRouter();
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('200');
  const [phoneError, setPhoneError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [notValidNumber, setNotValidNumber] = useState(false);
  const OPERATOR_VOLNA_ID = 1;

  const [sendTransaction, { isError, error, isSuccess, data }] = useAddPaymentTransactionMutation();

  const { data: phoneData, isError: isPhoneError } = useCheckOperatorQuery(phone, {
    skip: !phone.length || phone.length < 10,
  });

  const errorsHandler = (error: string) => {
    switch (true) {
      case error.includes('msisdn'):
        setPhoneError(`Номер ${error.split(' ').slice(1).join(' ')}`);
        break;
      default: {
        openModal?.();
      }
    }
  };

  const handleClickPaymentBtn = async () => {
    await sendTransaction({
      source_id: ORDER_PARAMS.SOURCE_ID,
      msisdn: phone,
      amount: Number(amount),
      payment_system_id: 1,
      email: '', // УБРАТЬ ЭТО!
      to_espp: true,
    }).unwrap();
    storage.set('successfulPurchaseModalTitle', 'Счет успешно оплачен');
    storage.set(
      'successfulPurchaseModalInfo',
      `Номер **${formatPhoneNumber(phone, false)}** пополнен на **${Number(amount)} ₽**.`,
    );
  };

  const isBtnDisabled = phone.length !== 10 || !amount || +amount < 10 || isPhoneError || !phoneData || notValidNumber;

  const handleChangePhoneNumber = (val: string) => {
    if (val !== phone) {
      setPhone(val);
      setPhoneError('');
    }
  };

  const mask = mobile
    ? {
        mask: [
          { mask: '+7 ({0}00) 000 - 00 - 00' },
          { mask: '+7 ({9}00) 000 - 00 - 00' },
          { mask: '+7 ({2}00) 000 - 00 - 00' },
        ],
        dispatch: (appended: string, dynamicMasked: MaskedDynamic<unknown>) => {
          const number = (dynamicMasked.value + appended).replace(/\D/g, '');

          if (number.startsWith('2') || number.startsWith('72')) {
            return dynamicMasked.compiledMasks[2];
          }

          return dynamicMasked.compiledMasks[1];
        },
      }
    : undefined;

  const placeholder = `+7 (${mobile ? '_' : '2'}__) ___ - __ - __`;
  const stringMask = !mobile ? '+7 ({2}00) 000 - 00 - 00' : undefined;

  useEffect(() => {
    if (!phoneData || phone.length < 10) {
      setNotValidNumber(false);
      return;
    }

    if (phoneData.err_msg === 'Не является абонентом наших операторов') {
      setNotValidNumber(true);
    } else if (phoneData.operator_id !== OPERATOR_VOLNA_ID) {
      setNotValidNumber(true);
    } else {
      setNotValidNumber(false);
    }
  }, [phoneData, phone]);

  useEffect(() => {
    if (Number(amount) < 10) {
      setAmountError('Сумма пополнения не менее 10 ₽');
    } else {
      setAmountError('');
    }
  }, [amount, phone]);

  useEffect(() => {
    const paymentError = getMyTypeError<PaymentError>(error, 'status');
    if (isError && paymentError) {
      const message = paymentError?.data?.detail || '';
      errorsHandler(message);
    }

    if (isSuccess && data?.payment_system_response) {
      const redirectURL = getOrigin() + router.asPath.substring(1);
      const url = data.payment_system_response.payment_url + `?redirect_url=${redirectURL}`;
      router.push(url);
    }
  }, [isError, error, data, isSuccess]);

  useEffect(() => {
    if (isPhoneError || notValidNumber) {
      if (isPhoneError) {
        setPhoneError('Ошибка проверки номера');
      } else {
        setPhoneError('Можно пополнить только счет Волны');
      }
    } else {
      setPhoneError('');
    }
  }, [isPhoneError, phoneData, notValidNumber]);

  return (
    <>
      <div className={cn(styles.container, lightMode && styles.light)}>
        <div className={styles.payment}>
          <Mir />
          {lightMode ? <VisaBlack /> : <Visa />}
          <Mastercard />
          <Sbp />
        </div>
        <div className={styles.form}>
          <PhoneInput
            darkBackground={!lightMode}
            onChange={handleChangePhoneNumber}
            className={styles.form__phone}
            placeholder={placeholder}
            error={phoneError}
            mask={mask}
            stringMask={stringMask}
          />

          <CurrencyInput
            darkBackground={!lightMode}
            onChange={setAmount}
            className={styles.form__currency}
            error={amountError}
            initialValue={amount}
          />
          {/* <SegmentedControl
          classNames={{
            root: styles.segment__root,
            control: styles.segment__control,
            label: styles.segment__label,
            indicator: styles.segment__indicator,
          }}
          data={[REPLENISHMENT_CONSTANTS.SEGMENT_CARD, REPLENISHMENT_CONSTANTS.SEGMENT_SBP]}
        /> */}
        </div>
        <div className={styles.button}>
          <Button
            onClick={handleClickPaymentBtn}
            classNames={{
              root: styles.button__root,
            }}
            disabled={isBtnDisabled}
          >
            {REPLENISHMENT_CONSTANTS.BUTTON}
          </Button>
        </div>
      </div>
    </>
  );
};
